import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { hourlyRate } from "../../components/Helpers";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import H from "../../components/Headline";
import ProfileCard from "../../components/ProfileCard";
import ContactForm from "../../components/ContactForm";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import { useAlternateLangs } from "../../components/Hreflangs";

const breadCrumbLevels = {
  Hem: "/",
  "Om mig": "/se/om",
  "Google Tag Manager-konsult": "/se/google-tag-manager-konsult"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/google-tag-manager-consultant"
);

const GoogleTagManagerConsultant = props => (
  <Layout location={props.location} alternateLangs={alternateLangs}>

    <React.Fragment>
      <SEO
        title="Freelance Google Tag Manager-konsult: M. Kupperschmidt"
        description="Matthias Kupperschmidt är en frilanskonsult och utvecklare för Google Tag Manager. Läs om hans bakgrund, tjänster och begär en offert."
        lang="se"
        canonical="/se/google-tag-manager-konsult"
        image="tag-management-consultant-hero"
        alternateLangs={alternateLangs}
      />
      <MainContent article>
        <Img
          src="tag-manager-consultant/matthias-kupperschmidt-presenting-at-founders-house-oct-2019.jpg"
          alt="Google Tag Manager frilanskonsult Matthias Kupperschmidt presenterar på Founders House, oktober 2019"
        />
        <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
        <H as="h1">Google Tag Manager-konsult</H>
        <ProfileCard
          tags={["Tracking Setup", "Retargeting", "Ecommerce Tracking", "Google Analytics", "Custom Event Tracking", "JavaScript", "React", "Python", "EN, DE, DA"]}
          profession="Google Tag Manager-konsult"
          rate={`${hourlyRate}€/tim`}
          location="remote från Köpenhamn, DK"
          cta="Kontakta"
          alt="GTM-expert"
        />
        <p>Jag är en Google Tag Manager-konsult och jag planerar och implementerar <Link to="/se/tagghantering">tagghanteringssystem</Link> för mina kunder.</p>
        <p>
          Med tagghanteringssystem kan du implementera flera tredjepartstags (t.ex. Google Analytics, Facebook
          pixel, Google Ads) på din webbplats snabbt och enkelt.
        </p>
        <p className="baseline">
          Tagghanteringsplattformar har blivit en mycket populär marknadsföringsteknologi de senaste åren. Faktum är att den globala marknaden för tagghanteringssystem förväntas fortsätta <a href="https://www.businesswire.com/news/home/20191209005363/en/Global-Tag-Management-System-Market-Analysis-Report" target="">växa med en årlig tillväxttakt på 5,7% fram till 2024</a>.
        </p>

        <p className="baseline">
          Google Tag Manager (GTM) är den mest använda tagghanteringslösningen med en <a href="https://trends.builtwith.com/analytics/tag-management/traffic/Entire-Internet" target="_blank">marknadsandel på 94% inom tagghanteringsmarknaden</a> och <b>gratis</b> i sin standardversion.
        </p>
        <p>
          Genom GTM implementerar jag spårningsinställningar integrerade med tredjepartstags som <b>skalbar över flera webbplatser</b> och
          språk.
        </p>
        <p>
          Det gör det möjligt för dig att använda användarinteraktioner från ditt Google Analytics-konto för att skapa användarsegment för dina marknadsföringsinsatser, som annonskampanjer på Facebook Ads, Doubleclick eller Linkedin.
        </p>
        <br />
        <br />
        <H as="h6" style={{ "textAlign": "center" }}>Vilka jag har arbetat med</H>
        <ImgScreenshot
          src="about/clients_700px.png"
          alt="kunder jag har arbetat med"
          className="article-img"
        />
        <br />
        <br />
        <br />

        <H as="h2" style={{ "textAlign": "center" }}>Få en offert</H>
        <ContactForm showHeadline={false} formName="ga konsult (SV) - kontaktformulär" />

        <H as="h2">Tjänster</H>
        <p>
          Som frilansande Google Tag Manager-konsult erbjuder jag samma tjänster (eller fler) som en typisk digital
          marknadsföringsbyrå. Du sparar tid och budget eftersom kommunikationen sker direkt med Google Tag Manager-experten. Ingen
          projektledning och administrativa kostnader.
        </p>
        <p>Här är en kort lista över mina <b>Google Tag Manager-konsulttjänster</b>:</p>
        <ul>
          <li>Google Tag Manager installation</li>
          <li>cookie banners</li>
          <li><Link to="/se/händelsespårning">event tracking</Link></li>
          <li>
            <Link to="/se/google-analytics-setup">Google Analytics-implementation</Link>
          </li>
          <li>kampanjkoordinering med media- och webbyråer</li>
          <li>förbättrad eCommerce-spårning</li>
          <li>
            <Link to="/se/data-lager">data layer-implementation</Link>
          </li>
          <li>dataintegration över tags</li>
          <li>retargeting med t.ex. Facebook, Google Ads, Twitter, Linkedin</li>
          <li>målförvandlingsspårning</li>
          <li>affiliate-spårning</li>
          <li>kampanjspårning</li>
          <li>videospårning</li>
          <li>Dataimport och export</li>
          <li>felsökning och problemlösning</li>
          <li>anpassad tagg-lösning med JavaScript</li>
        </ul>
        <p>
          Alla taggningslösningar kan snabbt implementeras och ändras via <b>GTM-container</b> eller någon annan tagghanteringslösning. GTM har utvecklats till en standard för hantering av data, marknadsföringstaggar, analys och anpassade taggimplementationer, främst eftersom ett GTM-konto är gratis och fungerar pålitligt.
        </p>
        <p>
          Sedan dess lansering kan marknadsförare äntligen genomföra och justera sin digitala kampanjstrategi mycket flexibelt eftersom inga ändringar i källkoden krävs.
        </p>
        <H as="h2">Vad är Google Tag Manager?</H>
        <p>
          Google Tag Manager är ett verktyg för att implementera och konfigurera taggar på en webbplats. Du kan definiera regler för utförandet av varje kodsnutt och exakt vilken data den har tillgång till.
        </p>
        <p>
          Allt görs från det användarvänliga Google Tag Manager-gränssnittet, som levererar en snabb och smidig utvecklarupplevelse. Implementering av nya taggar genom ett grafiskt användargränssnitt (GUI) i sig är en välkommen ny upplevelse, eftersom det kräver mindre koncentration och sparar implementeringstid.
        </p>
        <p>
          <b>Google Tag Manager är i huvudsak en <Link to="/se/wiki-analytics/behållar-tag">containertagg</Link> genom vilken du kan implementera alla andra marknadsföringstaggar på din
            webbplats</b>. Endast Google Tag Manager-kodsnutten går in i källkoden och de återstående kodsnuttarna kan implementeras och konfigureras i stor skala från Google Tag Manager-kontot.
        </p>
        <p>Du kan till och med testa varje ny tagg med dess förhandsgranskningsläge för att säkerställa att allt fungerar som avsett innan du skickar riktig data till ditt analyssystem.</p>
        <p className="baseline">
          Genom sin flexibilitet blev det mycket populärt för onlinemarknadsföring och datastyrning, särskilt med Googles produkter. Det är så populärt att det <a href="https://w3techs.com/technologies/overview/tag_manager" target="_blank">används av 30,4% av webbplatserna</a> på webben, <a href="https://trends.builtwith.com/widgets/Google-Tag-Manager" target="_blank">räknar 4,9 miljoner aktiva webbplatser som använder GTM</a>. Google Analytics-taggar som delar data med reklamtjänster (t.ex. Facebook, Google Ads) är typiska användningsfall.
        </p>
        <p>
          Läs en mer detaljerad{" "}
          förklaring av <Link to="/se/vad-ar-google-tag-manager">vad Google Tag Manager är</Link> och se några
          skärmdumpar av Google Tag Manager-koden och dess gränssnitt om du vill veta mer.
        </p>
        <p>
          Om du vill lära dig hur du använder Google Tag Manager med Google Analytics, läs min{" "}
          guide om <Link to="/se/google-analytics-setup">hur man implementerar en Google Analytics-setup med GTM</Link> och
          även <Link to="/se/händelsespårning">hur man ställer in event tracking med GTM</Link>.
        </p>

        <H as="h2">Vilka är fördelarna med Google Tag Manager?</H>
        <p>
          Den största fördelen med Google Tag Manager är den snabba och flexibla implementeringen av flera taggar och den skalbara datahanteringen bland dem.
        </p>
        <p>
          Dessa jämförelsevis små implementeringsändringar kostar tid och lägger upp för kunder som kör många digitala kampanjer. Bra programmerare är dyra och byråer har begränsade resurser. Därför är det meningsfullt att outsourca taggimplementeringsuppgifter till ett verktyg med grafiskt användargränssnitt (GUI) och snabbare implementeringstid.
        </p>
        <p>
          Läs mer av en{" "}
          <Link to="/se/fordelar-med-google-tag-manager">djupgående lista över fördelarna med Google Tag Manager</Link> för att veta mer.
        </p>

        <H as="h2">Varför behöver du Google Tag Manager-konsultation?</H>
        <p>
          En GTM-konsult planerar Google Analytics-implementeringen och ser till att andra marknadsföringstjänster får rätt data om t.ex. kampanjbesök och konverteringar för att optimera dina segment.
        </p>
        <p>
          Om du kör många kampanjer med individuella landningssidor, kommer en GTM-specialist snabbt att få konverteringsspårning tillagd till analysrapporterna.
        </p>
        <p>
          Genom att spåra händelserna på din landningssida kan du kontrollera den pågående prestandan för dina kampanjer. Om kampanjen inte presterar som förväntat kommer du att märka det tidigt och kan justera om. Denna feedback-cykel för varje kampanj ger lärdomar för att optimera framtida kampanjer.
        </p>
        <p>
          En Google Tag Manager-expert fungerar också som en utvecklare i ditt marknadsföringsteam och hjälper till med taggning, JavaScript-uppgifter och anpassade lösningar på din webbplats.
        </p>
        <p>
          GTM-konsultation blir allt viktigare för kunder som förlitar sig på andra digitala marknadsföringstjänster för sin verksamhet. Det maximerar värdet av varje sidvisning med högkvalitativa engagemangsdata om SEO, e-post och retargetingkampanjer för att hjälpa dig optimera dina annonsutgifter.
        </p>

        <H as="h2">Hur mycket kostar GTM-konsultation?</H>
        <p className="baseline">
          Min frilansavgift är <b>{hourlyRate}€</b> per timme netto. Om jag ska planera en Google Analytics-implementation för en kund, uppskattar jag arbetsomfånget och skickar ett förslag som beskriver alla leveranser. Omfånget beror på webbplatsens storlek och mängden KPI:er som ska spåras.
        </p>
        <p className="baseline">
          Webbplatsens underliggande teknik spelar också en roll när det gäller enkelhet vid implementation. Google Analytics-uppsättningar med event tracking och tredjepartsdataintegration kostar mellan <b>1800€</b> och <b>6000€</b> netto.
        </p>
        <p>
          Om du behöver kontinuerlig hjälp med Google Tag Manager för en större webbplats kan en månadsbudget enligt den förväntade arbetsbelastningen avtalas. Månatliga avtal bör omfatta minst 10 timmar per månad.
        </p>
        <p className="baseline">
          Mindre uppgifter debiteras med en fast avgift mellan <b>100€</b> och <b>300€</b>.
        </p>
        <H as="h2">Min profil</H>
        <p>
          Jag byggde min första webbplats när jag var 14 år gammal, för att byta filmer med mina klasskamrater. Vid 18 års ålder började jag driva en e-handel för bordfotboll som hette "Kickerkult". När jag började använda analyssystem, SEO och skickade mina första nyhetsbrev blev jag fast för webben. Sedan dess ville jag fokusera min karriär på internet.{" "}
        </p>
        <p>
          Jag har arbetat på digitala byråer sedan 2011 och fokuserade enbart på SEO och analys sedan 2015. Jag byggde tagghanteringssystem med GTM för e-handelssajter, stormarknadskedjor, biltillverkare, restaurangkedjor, försäkringsbolag och många andra sektorer. Mina kunder tenderar att vara företag, stora företag eller liknar en liknande organisation och procedurer internt.
        </p>
        <p>
          Jag har arbetat som egenföretagare som frilanskonsult för Google Tag Manager sedan 2018. Jag arbetar också med sökmotoroptimering och <Link to="/se/analysrådgivning">analyskonsultation</Link>. Allt från en hand.
        </p>
        <p>
          Min personlighet är analytisk, rationell och pragmatisk. Jag gillar analys för att hitta rätt lösning, men vill också inte göra saker komplicerade. Därför var det en logisk konsekvens att jag lämnade den hektiska byrån för att arbeta för mig själv som GTM-frilansare.
        </p>
        <p>
          För att hålla min kunskap uppdaterad med tagghantering, analys och SEO driver jag några webbplatser och besöker konferenser. Jag medorganiserar också Copenhagen SEO-Meetup.
        </p>
        <p>
          Du kan läsa mer om min bakgrund på{" "}
          <a href="https://www.linkedin.com/in/matthiaskupperschmidt">Linkedin</a> eller hitta mer information på min{" "}
          <Link to="/se/om">om-sida</Link>.
        </p>
        <H as="h2">Letar du efter en frilansande Google Tag Manager-specialist?</H>
        <p>
          Hör av dig om du letar efter någon som kan hjälpa dig med en Google Tag Manager-implementation. Jag kan konsultera dig om vilka händelser som ska spåras och vilka indikatorer du ska titta på för att optimera dina konverteringar.
        </p>
        <p>
          Begär en offert via <Link to="/se/kontakt">kontaktsidan</Link> så återkommer jag med ett förslag.
        </p>
      </MainContent>
    </React.Fragment>

  </Layout>
);

export default GoogleTagManagerConsultant;
